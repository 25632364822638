<template>
  <div class="grid">
		<div class="col-12">
            <div class="card">
                <Toast/>
                <Error :errors="errors"/>

                <Fieldset legend="Filter" :toggleable="true" :collapsed="true" class="mb-3">
                    <Filter @submit="getDataTable()" ref="filter"/>
                </Fieldset>

                <DataTable :value="dataTable" responsiveLayout="scroll" :loading="loading" dataKey="number" @sort="onSort($event)" :resizableColumns="true">
                    <template #header>
                        <div class="flex flex-column md:flex-row md:justify-content-between md:align-items-center">
                            <div>
                                <Button :loading="loadingExcel" label="Export Excel" icon="pi pi-file-excel" class="p-button p-button-success mr-2 my-1 inline-block" @click="exportExcelCSV('xlsx')" />
                                <Button :loading="loadingCsv" label="Export CSV" icon="pi pi-file-excel" class="p-button p-button-success mr-2 my-1 inline-block" @click="exportExcelCSV('csv')" />
                            </div>
                            <span class="block mt-2 md:mt-0 p-input-icon-left">
                                <i class="pi pi-search" />
                                <InputText v-model="search" placeholder="Cari..." @keyup.enter="getDataTable" />
                            </span>
                        </div>
                    </template>
                    <Column field="number" header="No." :style="{width:'20px'}" >
                        <template #body="slotProps">
                        {{ slotProps.data.number }}
                        </template>
                    </Column>
                    <Column field="project_name" header="Project">
                        <template #body="slotProps">
                            {{slotProps.data.project_name}}
                        </template>
                    </Column>
                    <Column field="username" header="Username">
                        <template #body="slotProps">
                            {{slotProps.data.username}}
                        </template>
                    </Column>
                    <Column field="nama" header="Name">
                        <template #body="slotProps">
                            {{slotProps.data.nama}}
                        </template>
                    </Column>
                    <Column field="provinsi_name" header="Provinsi">
                        <template #body="slotProps">
                            {{slotProps.data.provinsi_name}}
                        </template>
                    </Column>
                    <Column field="kabupaten_name" header="Kabupaten">
                        <template #body="slotProps">
                            {{slotProps.data.kabupaten_name}}
                        </template>
                    </Column>
                    <Column field="kecamatan_name" header="Kecamatan">
                        <template #body="slotProps">
                            {{slotProps.data.kecamatan_name}}
                        </template>
                    </Column>
                    <Column field="cust_account_type" header="Account Type">
                        <template #body="slotProps">
                            {{slotProps.data.cust_account_type}}
                        </template>
                    </Column>
                    <Column field="cust_location_name" header="Venue">
                        <template #body="slotProps">
                            {{slotProps.data.cust_location_name}}
                        </template>
                    </Column>
                    <Column field="cust_in_time" header="Customer In Time">
                        <template #body="slotProps">
                            {{slotProps.data.cust_in_time}}
                        </template>
                    </Column>
                    <Column field="coordinate" header="Customer In Coordinate">
                        <template #body="slotProps">
                            <div v-if="slotProps.data.cust_in_lat && slotProps.data.cust_in_lng">
                                <Map :id="slotProps.data.number  + 'in'" :lat="slotProps.data.cust_in_lat" :lng="slotProps.data.cust_in_lng"/><br>
                                <a :href="'https://maps.google.com/maps?q=loc:' + slotProps.data.cust_in_lat + ',' + slotProps.data.cust_in_lng" target="_blank"> {{ slotProps.data.cust_in_lat + ',' + slotProps.data.cust_in_lng }}</a>
                            </div>
                        </template>
                    </Column>
                    <Column field="cust_in_photo" header="Customer In Photo">
                        <template #body="slotProps">
                            <Image :src="slotProps.data.cust_in_photo" :alt="slotProps.data.cust_in_photo" width="100" preview />
                        </template>
                    </Column>
                    <Column field="cust_in_gps_address" header="Customer In GPS Address">
                        <template #body="slotProps">
                              <span v-html="wrapTextByLetters(slotProps.data.cust_in_gps_address, 80)"></span>
                        </template>
                    </Column>
                    <Column field="cust_out_time" header="Customer Out Time">
                        <template #body="slotProps">
                            {{slotProps.data.cust_out_time}}
                        </template>
                    </Column>
                    <Column field="coordinate" header="Customer Out Coordinate">
                        <template #body="slotProps">
                            <div v-if="slotProps.data.cust_out_lat && slotProps.data.cust_out_lng">
                                <Map :id="slotProps.data.number + 'out'" :lat="slotProps.data.cust_out_lat" :lng="slotProps.data.cust_out_lng"/><br>
                                <a :href="'https://maps.google.com/maps?q=loc:' + slotProps.data.cust_out_lat + ',' + slotProps.data.cust_out_lng" target="_blank"> {{ slotProps.data.cust_out_lat + ',' + slotProps.data.cust_out_lng }}</a>
                            </div>
                        </template>
                    </Column>
                    <Column field="cust_out_photo" header="Customer Out Photo">
                        <template #body="slotProps">
                            <Image :src="slotProps.data.cust_out_photo" :alt="slotProps.data.cust_out_photo" width="100" preview />
                        </template>
                    </Column>
                    <Column field="cust_out_gps_address" header="Customer Out GPS Address">
                        <template #body="slotProps">
                              <span v-html="wrapTextByLetters(slotProps.data.cust_out_gps_address, 80)"></span>
                        </template>
                    </Column>
                    <template #empty>
                        No data found.
                    </template>
                    <template #loading>
                        Loading data. Please wait.
                    </template>
                    <template #footer>
                        In total there are {{totalItemsCount ? totalItemsCount : 0 }} data.
                    </template>
                </DataTable>

                <Paginator v-model:rows="rows" v-model:first="offset" :totalRecords="totalItemsCount" :rowsPerPageOptions="[10,20,30]">
                    <template>
                        {{totalItemsCount}}
                    </template>
                </Paginator>
            </div>
      </div>
  </div>
</template>

<script>
import moment from 'moment';
import { mapGetters } from "vuex";
import { numberingDatatable } from '../../utils/helpers';
import Filter from '../../components/report/customer/Filter.vue';
import Error from '../../components/Error.vue';
import Map from '../../components/Map.vue';

export default {
    components: {
		'Filter': Filter,
		'Error': Error,
		'Map': Map,
	},
    data() {
        return {
            // loading
            loading: false,
            loadingExcel: false,
            loadingCsv: false,

            //datatables
            data: null,
            dataTable: null,
            rows: 10,
            search: null,
            offset: null,
            field: null,
            sort: null,
            page: null,
            totalItemsCount: 0,
        }
    },
    mounted() {
        this.getDataTable();
        this.$store.commit('setErrors', {});
    },
    watch: {
        offset() {
            this.getDataTable();
        },
        rows() {
            this.getDataTable();
        }
    },
    computed:{
        ...mapGetters(['errors']),
    },
    methods: {
        formatDate(value){
            if (value) {
                return moment.utc(value).format('DD-MM-YYYY')
            }
        },
        // Fungsi untuk membungkus teks setiap 20 huruf
        wrapTextByLetters(text, charLimit) {
            if (!text) return '';
            let result = '';
            
            for (let i = 0; i < text.length; i++) {
                result += text[i];
                // Tambahkan tag <br> setelah mencapai batas huruf
                if ((i + 1) % charLimit === 0) {
                    result += '<br>';
                }
            }
            return result.trim();
        },
        // DATATABLE
        getDataTable(){
          
            this.loading=true;
            this.$refs.filter.loading = true;
            this.page = (this.offset / this.rows) + 1;

            this.axios({
                method: 'GET',
                url: process.env.VUE_APP_ROOT_API + 'web/customer',
                params: {
                    "search" : this.search,
                    "per_page" : this.rows,
                    "page" : this.page,
                    "order_by" : this.field,
                    "sort_by" : this.sort,
                    "project_id" : this.$refs.filter.filters.project_id,
                    "start_date" : this.$refs.filter.period_start_label,
                    "end_date" : this.$refs.filter.period_end_label,
                }
            })
            .then(res => {
              this.data = res.data.data.data;
              this.dataTable = numberingDatatable(res.data.data.data, this.page, this.rows);
              this.totalItemsCount = res.data.data.total;
              this.rows = parseInt(res.data.data.per_page);
              this.loading=false;
              this.$refs.filter.loading = false;
            })
            .catch((err) => {
                console.log(err);
                this.loading=false;
                this.$refs.filter.loading = false;
            });
         
        },
        onSort(event) {
            this.field = event.sortField;
            this.sort = event.sortOrder == '1' ? 'ASC' : 'DESC';
            this.getDataTable();
        },
        // EXPORT
        exportExcelCSV(ext){

            if(
              this.$refs.filter.period_start_label !== null && 
              this.$refs.filter.period_end_label !== null && 
              this.$refs.filter.period_start_label !== 'Invalid date' && 
              this.$refs.filter.period_end_label !== 'Invalid date'
            ){  

                if(ext == 'xlsx'){
                    this.loadingExcel=true;
                }else if(ext == 'csv'){
                    this.loadingCsv=true;
                }

                this.axios({
                    method: 'GET',
                    url: process.env.VUE_APP_ROOT_API + 'web/customer/export',
                    responseType: 'blob',
                    params: {
                        "ext" : ext,
                        "project_id" : this.$refs.filter.filters.project_id,
                        "start_date" : this.$refs.filter.period_start_label,
                        "end_date" : this.$refs.filter.period_end_label,
                    }
                })
                .then(response => {
                    let fileUrl = window.URL.createObjectURL(response.data);
                    let fileLink = document.createElement('a');

                    fileLink.href = fileUrl;

                    fileLink.setAttribute('download', 'Report Customer Export.' + ext);
                    
                    document.body.appendChild(fileLink)

                    fileLink.click();
                    
                    if(ext == 'xlsx'){
                        this.loadingExcel=false;
                    }else if(ext == 'csv'){
                        this.loadingCsv=false;
                    }

                })
                .catch((err) => {
                    console.log(err);
                    
                    if(ext == 'xlsx'){
                        this.loadingExcel=false;
                    }else if(ext == 'csv'){
                        this.loadingCsv=false;
                    }
                });

            }else{
                 alert('Pastikan rentang waktu sudah benar atau tidak kosong');
            }
        },
    }
}
</script>
